<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <PageHeader :title="title" class="mb-3" />
    <v-row>
      <!-- Filtros -->
      <v-col cols="12">
        <v-card flat class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-card-text>
            <v-form
              v-model="isFormValid"
              ref="corte-form"
              id="corte-form"
              @submit.prevent="applyFilters()"
            >
              <v-row>
                <!-- solo clientes activos -->
                <v-col cols="3" md="3" class="py-0">
                  <v-switch
                    class="mt-3"
                    v-model="soloClientesActivos"
                    label="Solo clientes activos"
                  ></v-switch>
                </v-col>
                <!-- excluir clientes con licencia -->
                <v-col cols="4" md="5" class="py-0">
                  <v-switch
                    class="mt-3"
                    v-model="excluirClientesLicencia"
                    label="Excluir clientes con licencia perpetua"
                  ></v-switch>
                </v-col>
                <!-- Tipo de consulta -->
                <v-col
                  cols="5"
                  sm="6"
                  md="4"
                  align-self="center"
                  class="mt- 2py-0"
                >
                  <v-select
                    v-model="anio"
                    dense
                    outlined
                    multiple
                    :items="anioItems"
                    item-text="value"
                    item-value="value"
                    autocomplete="on"
                    clearable
                    :rules="rules.validAnio"
                    label="Año"
                  >
                    <!-- <template v-slot:prepend-item>
                      <v-list-item ripple @click="usuariosToggle()">
                        <v-list-item-action>
                          <v-icon :color="anio.length > 0 ? 'primary' : ''">
                            {{ multiselectIconAnios }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Todos </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template> -->
                    <template v-slot:selection="{ item, index }">
                      <v-chip class="mt-1" v-if="index === 0">
                        <span>{{ item.value }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ anio.length - 1 }} otros)
                      </span>
                    </template></v-select
                  >
                </v-col>
                <!-- Aplicar -->
              </v-row>
              <v-row class="mr-1 mb-2" justify="end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      size="28"
                      @click="resetForm"
                    >
                      {{ clearFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>Limpiar filtros</span>
                </v-tooltip>
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  elevation="2"
                  small
                  type="submit"
                  form="corte-form"
                >
                  Aplicar
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Data table -->
    <div v-if="datosFechasCorte.length > 0">
      <span style="font-size: smaller">
        Dentro de los datos resaltados, se puede acceder de manera directa para
        ver detalles</span
      >
    </div>
    <div :class="datosFechasCorte.length > 0 ? 'mt-5 mb-10' : 'mt-2 mb-10'">
      <div v-if="this.valoresTrue === true">
        <span style="color: red; font-size: smaller">
          Los datos que se muestran en color rojo, se encuentran con posibles
          problemas</span
        >
      </div>
      <v-card>
        <v-data-table
          class="elevation-2"
          :headers="datosFechasCorteHeaders"
          :items="datosFechasCorte"
          :loading="loading"
          no-data-text="No hay datos disponibles para mostrar"
          :search="searchConsulta"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="9" align-self="center" class="pt-5">
                  <v-text-field
                    v-model="searchConsulta"
                    clearable
                    label="Buscar"
                    single-line
                    :append-icon="searchIcon"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" align="end" class="pt-5 mt-2">
                  <v-btn
                    v-if="canCreateNewFecha"
                    color="primary"
                    @click="openModalNuevaFecha()"
                    class="to-right"
                    small
                  >
                    Nueva fecha de corte
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="canDeleteFechaCorte" left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.scriptId > 0"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="eliminarFechaCorte(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar fecha de corte</span>
            </v-tooltip>
          </template>
          <template v-slot:[`header`]="">
            <thead>
              <tr>
                <th
                  colspan="3"
                  class="fontColor"
                  style="text-align: right"
                ></th>
                <th colspan="1" style="text-align: center">|</th>
                <th colspan="2" class="fontColor" style="text-align: center">
                  Resultado script automático
                </th>

                <th colspan="" style="text-align: left">|</th>
              </tr>
            </thead>
          </template>
          <!-- Link para ver cantidad de clientes por fecha -->
          <template v-slot:[`item.cantClientes`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <a
                  @click="verDetalleCantClientes(item)"
                  v-bind="attrs"
                  v-on="on"
                  :style="{
                    color: item.rojoCantClientes ? 'red' : '',
                    textDecoration: 'underline',
                  }"
                >
                  {{ item.cantClientes }}
                </a>
              </template>
              <span>Ver clientes por fecha</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.scriptId`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <a
                  @click="verScript(item)"
                  v-bind="attrs"
                  v-on="on"
                  v-show="item.scriptId != 0"
                  :style="{ textDecoration: 'underline' }"
                >
                  {{ item.scriptId }}
                </a>
              </template>
              <span> Ver script</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.resultadoPendiente`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <a
                  @click="verDetallePendientes(item)"
                  v-bind="attrs"
                  v-on="on"
                  :style="{
                    color: item.rojoResultadoPendiente ? 'red' : '',
                    textDecoration: 'underline',
                  }"
                >
                  {{ item.resultadoPendiente }}
                </a>
              </template>
              <span :style="item.resultadoPendiente ? 'color:red' : ''">
                Ver detalle pendientes</span
              >
            </v-tooltip>
          </template>
          <template v-slot:[`item.resultadoOk`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <a
                  @click="verDetalleOk(item)"
                  v-bind="attrs"
                  v-on="on"
                  :style="{ textDecoration: 'underline' }"
                >
                  {{ item.resultadoOk }}
                </a>
              </template>
              <span> Ver detalle Ok</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.resultadoError`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <a
                  @click="verDetalleError(item)"
                  v-bind="attrs"
                  v-on="on"
                  :style="{ textDecoration: 'underline' }"
                >
                  {{ item.resultadoError }}
                </a>
              </template>
              <span> Ver detalle error</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- Modal para ver o copiar script -->
        <v-row justify="center">
          <v-dialog v-model="openModalScript" persistent max-width="50%">
            <v-card>
              <v-card-title>
                <!-- <span class="pl-0 primary--text"
                  >Script id: {{ idScript }}</span
                > -->
              </v-card-title>
              <v-col cols="12" md="12" class="py-0 pl-5">
                <v-textarea
                  dense
                  outlined
                  v-model="script"
                  label="Script"
                  type="text"
                  readonly
                  no-resize
                >
                  <template #append>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon @click="copyScript()" v-on="on">
                          <v-icon class="mb-3">{{
                            copied ? "mdi-check" : "mdi-content-copy"
                          }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ tooltipText }}</span>
                    </v-tooltip>
                  </template>
                </v-textarea>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined @click="closeModalScript()"> Cancelar </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card>
    </div>
    <v-dialog
      v-if="openModalNewFecha"
      v-model="openModalNewFecha"
      max-width="45%"
      @keydown.esc="closeModalNewFecha"
      persistent
    >
      <NewFechaCorte @closeModalNewFecha="closeModalNewFecha"></NewFechaCorte>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDeleteFechaDeCorte"
      :openDelete.sync="showDeleteModalFechaCorte"
      :maxWidth="'37%'"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import GoBackBtn from "@/components/shared/GoBackBtn";
import enumsAdmin from "@/utils/enums/modules/index.js";
import NewFechaCorte from "@/views/NewFechaCorte.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  directives: { mask },
  components: { PageHeader, GoBackBtn, DeleteDialog, NewFechaCorte },
  name: "FechasCorte",
  data() {
    return {
      anioItems: [],
      openModalScript: false,
      openModalNewFecha: false,
      showFilters: true,
      title: "Fechas de corte",
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      seeIcon: enums.icons.SEE,
      deleteIcon: enums.icons.DELETE,
      isFormValid: false,
      loading: false,
      anio: "",
      datosFechasCorte: [],
      routeToGo: "/licencias-clientes",
      rules: rules,
      soloClientesActivos: true,
      excluirClientesLicencia: true,
      tipoConsulta: null,
      searchConsulta: null,
      searchIcon: "mdi-magnify",
      script: null,
      copied: false,
      titleDeleteFechaDeCorte: "¿Desea eliminar la fecha de corte?",
      mSCloseBoxIcon: enums.icons.CLOSE_BOX,
      checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
      fechaCorteProp: null,
      valoresTrue: false,
      showDeleteModalFechaCorte: false,
      idScript: null,
      filtrosGuardados: {},
      optionCode: enums.webSiteOptions.FECHA_CORTE,
      canCreateNewFecha: false,
      allowedActions: null,
      canDeleteFechaCorte: false,
      fechaConvertida: null,
      datosFechasCorteHeaders: [
        {
          text: "Fecha",
          sortable: false,
          value: "fechaCorte",
        },
        {
          text: "Cant. clientes",
          sortable: false,
          value: "cantClientes",
          align: "end",
        },
        { text: "Script id", sortable: false, value: "scriptId", align: "end" },
        { text: "Ok", sortable: false, value: "resultadoOk", align: "end" },
        {
          text: "Error",
          sortable: false,
          value: "resultadoError",
          align: "end",
        },
        {
          text: "Pendiente",
          sortable: false,
          value: "resultadoPendiente",
          align: "end",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
    };
  },

  async mounted() {
    if (this.$store.state.filtersConsultaFecha != null) {
      this.soloClientesActivos =
        this.$store.state.filtersConsultaFecha.soloClientesActivos;
      this.excluirClientesLicencia =
        this.$store.state.filtersConsultaFecha.excluirLicPerpetua;
      this.anio = this.$store.state.filtersConsultaFecha.anioFechas
        ? this.$store.state.filtersConsultaFecha.anioFechas
        : [];
      this.generateAnioItems();
      await this.$nextTick();
      this.applyFilters();
    }
    this.$store.state.filtersConsultaFecha = null;
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 2;
    const endYear = currentYear + 1;
    //Ordenado de mayor a menor
    this.anioItems = Array.from(
      { length: endYear - startYear + 1 },
      (_, index) => {
        const year = endYear - index;
        return { value: year, text: year.toString() };
      }
    );
  },
  computed: {
    tooltipText() {
      return this.copied ? "Script copiado" : "Copiar script";
    },
    multiselectIconAnios() {
      if (this.selectAllAnios) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllAnios() {
      return this.anio && this.anio.length === this.anioItems.length;
    },
  },
  async created() {
    // this.setPermisos();
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      fetchFechasCortePorCliente: "clientesData/fetchFechasCortePorCliente",
      deleteFechaCorte: "clientesData/deleteFechaCorte",
      setAlert: "user/setAlert",
      getScriptbyFecha: "clientesData/getScriptbyFecha",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enumsAdmin.adminSistema.permissions.NUEVA_FECHA_CORTE:
            this.canCreateNewFecha = true;
            break;
          case enumsAdmin.adminSistema.permissions.DELETE_FECHA_CORTE:
            this.canDeleteFechaCorte = true;
            break;
          default:
            break;
        }
      });
    },
    generateAnioItems() {
      if (typeof this.anio === "string") {
        // Convierte la cadena en un array dividiéndola por comas y eliminando los espacios
        this.anio = this.anio
          .split(",")
          .map((year) => parseInt(year.trim(), 10));
      }
      // Verificamos si this.anio es un array antes de usar map
      if (Array.isArray(this.anio)) {
        this.anioItems = this.anio.map((year) => ({
          value: year,
          text: year.toString(),
        }));
      } else {
        console.error("Error al convertir el año", this.anio);
      }
    },
    openModalNuevaFecha(item) {
      this.openModalNewFecha = true;
      this.registrosParams = item;
    },
    closeModalNewFecha() {
      this.openModalNewFecha = false;
    },
    usuariosToggle() {
      this.$nextTick(() => {
        if (this.selectAllAnios) this.anio = [];
        else this.anio = this.anioItems.map((x) => x.value);
      });
    },
    convertirFecha(formatoOriginal) {
      // Dividir la fecha original en partes
      const partes = formatoOriginal.split("/");
      // Crear un objeto Date con las partes de la fecha
      const fecha = new Date(partes[2], partes[1] - 1, partes[0]);
      // Obtener las partes de la fecha en el nuevo formato
      const nuevoFormato =
        fecha.getFullYear() +
        "-" +
        ("0" + (fecha.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + fecha.getDate()).slice(-2) +
        " 00:00:00.000";
      return nuevoFormato;
    },
    verScript(item) {
      this.openModalScript = true;
      this.scriptByFecha = item.fechaCorte;
      this.fechaConvertida = this.convertirFecha(this.scriptByFecha);
      this.loadScript();
    },
    async loadScript() {
      const response = await this.getScriptbyFecha(this.fechaConvertida);
      this.script = response.script;
    },

    eliminarFechaCorte(item) {
      this.showDeleteModalFechaCorte = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      if (
        this.itemToDelete.cantClientes > 0 ||
        this.itemToDelete.resultadoOk > 0
      ) {
        this.showDeleteModalFechaCorte = false;
        this.setAlert({
          type: "warning",
          message:
            "No se pueden eliminar fecha de corte que tengan cantidad de clientes o resultados OK.",
        });
      } else {
        const response = await this.deleteFechaCorte({
          scriptId: this.itemToDelete.scriptId,
          fechaCorte: this.parseDateToIso(this.itemToDelete.fechaCorte),
        });
        if (response.status === 200) {
          this.showDeleteModalFechaCorte = false;
          this.setAlert({
            type: "success",
            message: "Eliminado con éxito.",
          });
          this.uploadScripts();
        }
      }
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModalScript() {
      this.openModalScript = false;
    },
    copyScript() {
      const textArea = document.createElement("textarea");
      textArea.value = this.script;
      // Establecer el área de texto fuera del área visible de la página
      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      document.body.appendChild(textArea);
      textArea.select();
      try {
        const successful = document.execCommand("copy");
        this.copied = successful;
        if (successful) {
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        }
      } catch (err) {
        console.error("Error copiando script:", err);
      }
      document.body.removeChild(textArea);
    },
    verDetalleCantClientes(item) {
      this.$router.push({
        name: "ClientesXFechaCorte",
        params: {
          itemsCantClientes: item,
        },
      });
      this.$store.state.filtersConsultaFecha = this.filtrosGuardados;
    },
    verDetallePendientes(item) {
      this.$router.push({
        name: "StatusEjecucionAutomatica",
        params: {
          items: item,
          clickOn: "Pendiente",
        },
      });
      this.$store.state.filtersConsultaFecha = this.filtrosGuardados;
    },
    verDetalleOk(item) {
      this.$router.push({
        name: "StatusEjecucionAutomatica",
        params: {
          items: item,
          clickOn: "Ok",
        },
      });
      this.$store.state.filtersConsultaFecha = this.filtrosGuardados;
    },
    verDetalleError(item) {
      this.$router.push({
        name: "StatusEjecucionAutomatica",
        params: {
          items: item,
          clickOn: "Error",
        },
      });
      this.$store.state.filtersConsultaFecha = this.filtrosGuardados;
    },
    async applyFilters() {
      this.$store.state.filtersConsultaFecha == null;
      this.loading = true;
      const data = {
        soloClientesActivos: this.soloClientesActivos,
        excluirLicPerpetua: this.excluirClientesLicencia,
        anioFechas: Array.isArray(this.anio) ? this.anio.join(", ") : this.anio,
      };
      this.filtrosGuardados = {
        soloClientesActivos: this.soloClientesActivos,
        excluirLicPerpetua: this.excluirClientesLicencia,
        anioFechas: Array.isArray(this.anio)
          ? this.anio.join(", ")
          : this.anio === " "
          ? []
          : [this.anio],
      };
      const response = await this.fetchFechasCortePorCliente(data);
      this.datosFechasCorte = response.data.data;
      this.valoresTrue = this.datosFechasCorte.some(
        (valor) => valor.rojoCantClientes === true
      );
      this.loading = false;
    },
    resetForm() {
      this.anio = "";
      this.soloClientesActivos = true;
      this.excluirClientesLicencia = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.no-upper-case {
  text-transform: none;
}
.fontsize {
  font-size: 12px;
}
</style>
